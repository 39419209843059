import { Component, OnInit } from "@angular/core";
import { NewsService } from "src/app/services/news.service";
import { ArticlePreview, Headline } from "src/app/models/news";
import { PageService } from 'src/app/service/page.service';
import { Blog } from 'src/app/models/page';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: "app-latest",
  templateUrl: "./latest.component.html",
  styleUrls: ["./latest.component.less"],
})
export class LatestComponent implements OnInit {
  ArticlePreviews: Blog[];
  Headlines: Blog[];
  CurrentArticlePreview: Blog;
  state:number = 0;
  autoTimer;
  numberOfPreview= 0;

  constructor(private newsService: NewsService,private _service: PageService,private router: Router,
    private route:ActivatedRoute,
    private titleService: Title) {
    
    this.titleService.setTitle("News | Uniphar Medtech");
    this.newsService.getFeaturedNews().subscribe((d) => {
      this.ArticlePreviews = d.result as Blog[];
      this.numberOfPreview = this.ArticlePreviews.length;
      this.ArticlePreviews = this.ArticlePreviews.sort(function(a, b) {
        return a.index - b.index;
      });

      this.CurrentArticlePreview = this.ArticlePreviews[0];
      this.state = 0;

      this.newsService.getPaginatedNews().subscribe(d => {
        this.Headlines = d.result as Blog[];
        //this.Headlines = this.Headlines.filter(x => !x.isFeatured);
        this.Headlines = this.Headlines.sort(function(a, b) {
          return a.index - b.index;
        });
      })
    });
    console.log(this.CurrentArticlePreview);
    this.startTimer();
  }

  startTimer(): void {
    this.autoTimer = window.setInterval(() => {
      this.state = this.getNextStateUp();
      this.SetStateId(this.state);
    }, 8000);
  }

  SetState(newState:number) {
    this.stopTimer();
    this.state = newState;
    this.CurrentArticlePreview = this.ArticlePreviews[this.state];
    this.startTimer();
  }

  ngOnInit(): void {
  }

  stopTimer(): void {
    window.clearInterval(this.autoTimer);
  }

  onSwipeLeft(ev) {
    this.SetStateId(this.getNextStateUp());
  }

  onSwipeRight(ev) {
    this.SetStateId(this.getNextStateDown());
  }

  getNextStateUp(): number {
    var nextState: number;
    nextState = this.state + 1;
    if (nextState == this.numberOfPreview) nextState = 0;
    return nextState;
  }

  getNextStateDown(): number {
    var nextState: number;
    nextState = this.state - 1;
    if (nextState < 0) nextState = this.numberOfPreview-1;
    return nextState;
  }

  

  SetStateId(x:number){
    this.stopTimer();
    this.state = x;
    //this.currentTestimonial = this.testimonials[this.state];
    this.CurrentArticlePreview = this.ArticlePreviews[this.state];
    this.startTimer();
  }

  onView(item : Blog){
    this.router.navigate([item.blogUrl, { id: item.id} ], { relativeTo: this.route, state: {selectedItem: item} });
  }
}
