<div class="row full-height">
  <div class="col full-height left" (mouseover)="IsOpeningLeft=true" (mouseout)="IsOpeningLeft=false">
    <div class="image-container"></div>
    <a routerLink="/medical-device-distribution">
      <div class="red">
        <div class="copy">
          <ul>
            <li>Medical Device<br />Distribution</li>
            <li>
              <app-arrow-only [TextColor]="'white'" [IsOpening]="IsOpeningLeft"></app-arrow-only>
            </li>
          </ul>
        </div>
      </div>
    </a>
  </div>
  <div class="col right" (mouseover)="IsOpeningRight=true" (mouseout)="IsOpeningRight=false">
    <div class="image-container"></div>
    <a routerLink="/specialities">
      <div class="red">
        <div class="copy">
          <ul>
            <li>Medical<br />Specialities</li>
            <li>

              <app-arrow-only [TextColor]="'white'" [IsOpening]="IsOpeningRight"></app-arrow-only>

            </li>
          </ul>
        </div>
      </div>
    </a>
  </div>
</div>
