<div class="row">
  <div class="col-2 center-text d-none d-md-block">
    <img class="logo" src="../../../assets/images/logo-f.png" />
  </div>
  <div class="col-10">
    <div class="row">
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <li class="title"><a routerLink="/">Home</a></li>
          <li><a routerLink="/history">History</a></li>
          
          <li><a routerLink="/vision">Vision, Mission</a></li>
          <li><a routerLink="/social">Our Social Contribution</a></li>
          <li><a routerLink="/culture">Our Culture</a></li>
        </ul>
      </div>
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <li class="title"><a href="/what-we-do">What we do</a></li>
          <li><a href="/suppliers">Suppliers</a></li>
          <li><a href="/specialities">Specialities</a></li>
          <li><a href="/medical-device-distribution">Medical device Distribution</a></li>
        </ul>
      </div>
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <li class="title"><a href="/we-do-more-intro">We do more</a></li>
          <li><a routerLink="/value-chain">Value Chain</a></li>
          <li><a routerLink="/ourpeople">Sales Structure</a></li>
          <li><a routerLink="/we-do-more">Support Divisions</a></li>
        </ul>
      </div>
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <li class="title"><a href="/investors">Investors</a></li>
          <li><a href="/investors">Overview</a></li>
          <li><a href="/investors/acquisitions">Acquisitions</a></li>
          <li><a target="_blank" href="https://www.uniphar.ie/">Uniphar</a></li>
        </ul>
      </div>
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <li class="title"><a href="/careers">Careers</a></li>
          <li><a href="/careers/testimonials">Testimonials</a></li>
          <li><a target="_blank" href="https://apply.workable.com/uniphar-medtech/?lng=en">Available Positions</a></li>
        </ul>
      </div>
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <a href="/ourbusinesses">
            <li class="title">Our Businesses</li>
          </a>
          <a href="ourbusinesses/cardiac-services">
            <li>Cardiac Services</li>
          </a>
          <a href="ourbusinesses/med-surgical">
            <li>M.E.D Surgical</li>
          </a>
          <a href="ourbusinesses/tekno-surgical">
            <li>Tekno Surgical</li>
          </a>
          <a href="ourbusinesses/synapse-medical">
            <li>Synapse Medical</li>
          </a>
          <a href="ourbusinesses/macromed">
            <li>MacroMed</li>
          </a>
          <a href="ourbusinesses/angiocare">
            <li>Angiocare</li>
          </a>
          <a href="ourbusinesses/m3-medical">
            <li>M3 Medical</li>
          </a>
          <a href="ourbusinesses/eps-vascular">
            <li>EPS Vascular</li>
          </a>
          <a href="ourbusinesses/correct-medical">
            <li>CoRRect Medical</li>
          </a>
        </ul>
      </div>
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <a href="/news"><li class="title">News</li></a>
          <a href="/news"><li>Latest Articles</li></a>
        </ul>
      </div>
      <div class="col-xl col-lg-3 col-sm-4 col-6">
        <ul>
          <a href="/contact">
            <li class="title">Contact</li>
          </a>
        </ul>
      </div>
    </div>
  </div>
</div>
