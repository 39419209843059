import { Component, OnInit } from '@angular/core';
import { CallingcodesService } from 'src/app/services/callingcodes.service';
import { CallingCode } from 'src/app/models/callingcode';

@Component({
  selector: 'app-calling-code',
  templateUrl: './calling-code.component.html',
  styleUrls: ['./calling-code.component.less']
})
export class CallingCodeComponent implements OnInit {

  CallingCodes: CallingCode[];

  constructor(private CallingCodesServices: CallingcodesService) { }

  ngOnInit(): void {
    this.CallingCodesServices.getCallingCodes().subscribe(d => this.CallingCodes = d);
  }

}
