import { PageService } from 'src/app/service/page.service';
import { Page, PageComponentAsset } from 'src/app/models/page';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { stringify } from 'querystring';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less']
})
export class AboutUsComponent implements OnInit {

  pageCode: string = "ABTUS001";
  title: string = "";
  resource: string = "";
  resourceDesktop: string = "";
  assets: PageComponentAsset[];
  pageResource: Page;
  logo: any;

  constructor(private _service: PageService, private titleService: Title) {
    this.titleService.setTitle("Home | Uniphar Medtech");
   }

  ngOnInit(): void {
    this.loadReferenceData();
  }

  loadReferenceData(){
    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
          this.pageResource = result as Page;
          this.logo = this.pageResource.pageComponents[0].pageComponentAssets.find(x => x.altAttribute == "logo")?.filePath ?? "";
          this.title = this.pageResource.pageComponents[0].sectionTitle;
          this.resource = this.pageResource.pageComponents[0].sectionContent;
          let sanitized = this.strip(this.resource);
          let isTitleInSource = sanitized.toLowerCase().includes(this.title.toLowerCase());
          
          this.resourceDesktop = this.resource; 
          if(isTitleInSource)
          {
            var splittedString = this.resource.split('.');
            this.resourceDesktop = this.resource.replace(`${splittedString[0]}. `,'');

          }
          
          this.assets = this.pageResource.pageComponents[0].pageComponentAssets;
          //console.log(this.pageResource);
      }
  });

  }


  strip(html){
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
 }

}
