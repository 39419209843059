<div class="padding-container">
  <div class="atf atf-init desktop social-height" >
    <div class="row full-height">
      <div class="col-12 col-lg-6 full-height">
        <h1>Our Social Contribution</h1>
        <app-pager [state]="currentState" (onStateChanged)="SetState($event)"></app-pager>
        <app-copy-ticker [state]="currentState"  [pageComponent]="pageResource?.pageComponents"></app-copy-ticker>
      </div>
      <div class="col-12 col-lg-6 full-height">
        <div class="center-vertical attach-right ctr" >
          <app-social-slides [state]="currentState" [pageComponent]="pageResource?.pageComponents"></app-social-slides>
          <div class="next-step">
            <!--<app-arrow-button [ButtonText]="'Our Culture'" [Target]="'/culture'" ></app-arrow-button>-->
            <a routerLink="/culture">
              <ul class="attention">
                <li>
                  <app-attention-bar></app-attention-bar>
                </li>
                <li><p>Our Culture</p></li>
              </ul>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="mobile" id="mobile">
    <div class="mobile-slide-container" (swipeleft)="onSwipeLeft($event)" (swiperight)="onSwipeRight($event)">
    <app-social-slides-mobile [state]="currentState" (onStateChanged)="mobileStateChanged($event)" [pageComponent]="pageResource?.pageComponents"></app-social-slides-mobile>
  </div>
    <br /><br />
    <div class="next-mobile">
      <!--<app-arrow-button [ButtonText]="'Our Culture'" [Target]="'/culture'"></app-arrow-button>-->
      <a routerLink="/culture">
        <ul class="attention">
          <li>
            <app-attention-bar></app-attention-bar>
          </li>
          <li>Our Culture</li>
        </ul>
      </a>
    </div>
  </div>
</div>
<app-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\\'>Home</a>&nbsp;&nbsp;|&nbsp;&nbsp;Our Social Contribution'"></app-breadcrumb>
