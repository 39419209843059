import { Component, OnInit } from '@angular/core';
import { Blog } from 'src/app/models/page';
import { NewsService } from 'src/app/services/news.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeHtml, Title } from '@angular/platform-browser';
import { SIGSEGV } from 'constants';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
     selector: 'app-article',
     templateUrl: './article.component.html',
     styleUrls: ['./article.component.less']
})
export class ArticleComponent implements OnInit {

     selectedContent: Blog;
     idForViewing: any;
     writeUp: SafeHtml;

     
     

     constructor(private route: ActivatedRoute, private _service: NewsService, private router: Router,
          private titleService: Title, private sanitizer: DomSanitizer) {

          //get id in route
          this.route.paramMap.subscribe(params => {
               if (params.get('id')) {
                    this.idForViewing = +params.get('id');
               }
          });

          if (this.router.getCurrentNavigation().extras.state) {
               this.selectedContent = this.router.getCurrentNavigation().extras.state.selectedItem;
               this.writeUp = this.sanitizer.bypassSecurityTrustHtml(this.selectedContent.writeUp);
          }
          else {
               if (!this.idForViewing) {
                    this.router.navigate(["news"]);
               }
               else {
                    this._service.getPaginatedNews().subscribe(
                         newsTable => {
                              var listOfResult = newsTable.result as Blog[];
                              this.selectedContent = listOfResult.find(x => x.id == this.idForViewing);
                              this.writeUp = this.sanitizer.bypassSecurityTrustHtml(this.selectedContent.writeUp);
                         }
                    );
               }


          }

     }
     ngOnInit(): void {
          if (this.selectedContent) {
               this.titleService.setTitle(`News Article - ${this.selectedContent.blogTitle} | Uniphar Medtech`);
               this.writeUp = this.sanitizer.bypassSecurityTrustHtml(this.selectedContent.writeUp);
          }
          else {
               this.titleService.setTitle(`News Article | Uniphar Medtech`);
          }
     }

}
