import { Component, OnInit, Input } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-our-vision',
  templateUrl: './our-vision.component.html',
  styleUrls: ['./our-vision.component.less']
})
export class OurVisionComponent implements OnInit {

  @Input() pageComponent: PageComponent;
  vision1: string;
  vision2: string;
  vision1N2: string;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.pageComponent)
    {
        this.pageComponent.pageComponentAssets.forEach(element => {
          if(element.seoFileName == 'vision-1')
          {
            this.vision1 = element.filePath;
          }
          else if(element.seoFileName == 'vision-2')
          {
            this.vision2 = element.filePath;
          }
          else if(element.seoFileName == 'vision-1.2')
          {
            this.vision1N2 = element.filePath;
          }
          
        });

    }
  }
}
