import { Component, OnInit } from '@angular/core';
import { Page, PageComponent } from 'src/app/models/page';
import { PageService } from 'src/app/service/page.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-medical-device-distribution',
  templateUrl: './medical-device-distribution.component.html',
  styleUrls: ['./medical-device-distribution.component.less']
})
export class MedicalDeviceDistributionComponent implements OnInit {

  pageCode: string = "MDD01";
  pageResource: Page;
  pageMainComponent: PageComponent;
  pageTopComponent: PageComponent;
  pageBottomComponent: PageComponent;
  pageTopComponentMobile: PageComponent;

  constructor(private _service: PageService, private titleService: Title) {
    this.titleService.setTitle("Medical Device Distribution | Uniphar Medtech");
   }

  ngOnInit(): void {
    this.loadReferenceData();
  }

  loadReferenceData(){
    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
        this.pageResource = result as Page;
        this.pageMainComponent = result.pageComponents.find(x => x.id == 27);



        this.pageTopComponent = result.pageComponents.find(x => x.id == 29);
        this.pageBottomComponent = result.pageComponents.find(x => x.id == 28);

        this.pageTopComponentMobile = result.pageComponents.find(x => x.id == 30);
        console.log(this.pageResource);
      }
    });

  }
}
