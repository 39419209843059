import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.less']
})
export class ArrowButtonComponent implements OnInit {
  @Input() TextColor:string="black";
  @Input() Target:string="";
  @Input() ButtonText="Default";
  @Input() FontSize:string="14";
  @Input() LinkExternal: boolean = false;
  @Output() onClicked:EventEmitter<null>;

  constructor() {
    this.onClicked = new EventEmitter<null>();
  }

  ngOnInit(): void {
  }

  ButtonClicked() {
    this.onClicked.emit();
  }
}
