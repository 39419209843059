import { Injectable } from '@angular/core';
import { CallingCode, CALLINGCODES } from '../models/callingcode';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallingcodesService {

  constructor() { }

  getCallingCodes(): Observable<CallingCode[]> {
    return of(CALLINGCODES);
  }
}
