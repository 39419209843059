import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { VisionComponent } from './views/vision/vision.component';
import { SocialComponent } from './views/social/social.component';
import { CultureComponent } from './views/culture/culture.component';
import { OverviewComponent } from './views/what-we-do/overview/overview.component';
import { MedicalDeviceDistributionComponent } from './views/what-we-do/medical-device-distribution/medical-device-distribution.component';
import { SuppliersComponent } from './views/what-we-do/suppliers/suppliers.component';
import { SpecialitiesComponent } from './views/what-we-do/specialities/specialities.component';
import { Speciality } from './models/speciality';
import { ContactUsComponent } from './views/contact/contact-us/contact-us.component';
import { LatestComponent } from './views/news/latest/latest.component';
import { ArticleComponent } from './views/news/article/article.component';
import { WeDoMoreIntroComponent } from './views/we-do-more/we-do-more-intro/we-do-more-intro.component';
import { WeDoMoreValueChainComponent } from './views/we-do-more/we-do-more-value-chain/we-do-more-value-chain.component';


const routes: Routes = [
  { path: "", component: AboutUsComponent },
  { path: "vision", component: VisionComponent},
  { path: "social", component: SocialComponent},
  { path: "culture", component: CultureComponent},
  { path: "what-we-do", component: OverviewComponent},
  { path: "medical-device-distribution", component: MedicalDeviceDistributionComponent},
  { path: "suppliers", component: SuppliersComponent},
  { path: "specialities", component: SpecialitiesComponent},
  { path: "we-do-more-intro", component: WeDoMoreIntroComponent},
  { path: "value-chain", component: WeDoMoreValueChainComponent},
  { path: "contact", component: ContactUsComponent},
  { path: "news", component: LatestComponent},
  { path: "news/article", component: ArticleComponent},
  { path: "news/:something", component: ArticleComponent},
  { path: 'legal', loadChildren: () => import('./legal-module/legal-module.module').then(m => m.LegalModuleModule) },
  { path: 'careers', loadChildren: () => import('./careers-module/careers-module.module').then(m => m.CareersModuleModule) },
  { path: 'shared-module', loadChildren: () => import('./shared-module/shared-module.module').then(m => m.SharedModuleModule) },
  { path: 'investors', loadChildren: () => import('./investors-module/investors-module.module').then(m => m.InvestorsModuleModule) },
  { path: 'specialities', loadChildren: () => import('./specialities-module/specialities-module.module').then(m => m.SpecialitiesModuleModule) },
  { path: 'we-do-more', loadChildren: () => import('./we-do-more-module/we-do-more-module.module').then(m => m.WeDoMoreModuleModule) },
  { path: 'ourbusinesses', loadChildren: () => import('./ourbusinesses-module/ourbusinesses-module.module').then(m => m.OurbusinessesModuleModule) },
  { path: 'history', loadChildren: () => import('./history-module/history-module.module').then(m => m.HistoryModuleModule) },
  { path: 'ourpeople', loadChildren: () => import('./ourpeople-module/ourpeople-module.module').then(m => m.OurpeopleModuleModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
