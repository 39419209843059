import { Component, OnInit, Input } from '@angular/core';
import { Speciality, ContentItem } from 'src/app/models/speciality';

@Component({
  selector: 'app-speciality-summary',
  templateUrl: './speciality-summary.component.html',
  styleUrls: ['./speciality-summary.component.less']
})
export class SpecialitySummaryComponent implements OnInit {
  @Input() Speciality: ContentItem;
  constructor() { }

  ngOnInit(): void {
  }

  getProfilePicture(): string {
    if (this.Speciality) {
      return this.Speciality.contentAlt
    } else return "../../../../assets/images/what-we-do/specialities/main.jpg";
  }

}
