import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-mdd-bottom',
  templateUrl: './mdd-bottom.component.html',
  styleUrls: ['./mdd-bottom.component.less']
})
export class MddBottomComponent implements OnInit {

  @Input() pageComponent: PageComponent;
  constructor() { }

  ngOnInit(): void {
    this.loadResources();
  }

  ngOnChanges() {
    this.loadResources();
  }

  loadResources()
  {
      if(this.pageComponent)
      {
        let image = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "mdd-bottom")
        if(image)
        {
          document.documentElement.style.setProperty('--mdd-bg-bottom-url',`url("${image.filePath}") no-repeat center center`);
        }

      }

  }

}
