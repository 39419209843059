import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-percentage-block-text',
  templateUrl: './percentage-block-text.component.html',
  styleUrls: ['./percentage-block-text.component.less']
})
export class PercentageBlockTextComponent implements OnInit {
  @Input() BodyText:string;

  constructor() { }

  ngOnInit(): void {
  }

  getHtml(): string {
    return this.BodyText.replace("*", "<span class='highlight'>").replace("*", "</span>");
  }

}
