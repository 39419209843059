import { Injectable } from '@angular/core';
import { ArticlePreview, ARTICLES, Headline, HEADLINES } from '../models/news';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, empty } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Blog, Table } from '../models/page';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }
  private _url: string = environment.apiUrl;
  private serviceUrl: string = this._url+"/api/v1/cms/";

  generateOptions(): HttpHeaders {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return headers;
  }


  getNewsArticlePreviews(): Observable<ArticlePreview[]> {
    return of(ARTICLES);
  }

  getHeadlines(): Observable<Headline[]> {
    return of(HEADLINES);
  }

  getFeaturedNews(): Observable<Table>  {
    return this.http.get<Table>(this.serviceUrl + "blog/featured",  { headers: this.generateOptions() })
    .pipe(map((data: Table) => {
        console.log(data)
        return data;
        }, catchError(err => { 
          return empty();
        }))
    )
  }

  getPaginatedNews(searchString: string = "",
    pageNo: any= null,
    pageSize: any = null): Observable<Table> {
      if(pageNo && pageSize)
      {
        return this.http.get<Table>(this.serviceUrl+ "blog/all?searchString="+ searchString +"&pageNo=" + pageNo+ "&pageSize=" + pageSize,{ headers: this.generateOptions() })
        .pipe(map((data: Table) => {
            console.log(data)
            return data;
            }, catchError(err => { 
              return empty();
            }))
        )

      }
      else
      {
        return this.http.get<Table>(this.serviceUrl+ "blog/all?searchString="+ searchString,{ headers: this.generateOptions() })
        .pipe(map((data: Table) => {
            console.log(data)
            return data;
            }, catchError(err => { 
              return empty();
            }))
        )

      }
      
  }



}
