import { Component, Input, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-what-we-do-links-l',
  templateUrl: './what-we-do-links-l.component.html',
  styleUrls: ['./what-we-do-links-l.component.less']
})
export class WhatWeDoLinksLComponent implements OnInit {

  @Input() pageComponent: PageComponent;

  IsOpeningLeft:boolean = false;
  IsOpeningRight:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    this.loadImages();
  }

  loadImages() {
    if(this.pageComponent)
    {
      let one = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "wwd-1")
      let two = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "wwd-2")
      let three = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "wwd-3")
//filePath
      document.documentElement.style.setProperty('--wwd-bg-1-url',`url("${one.filePath}")`);
      document.documentElement.style.setProperty('--wwd-bg-2-url',`url("${two.filePath}")`);
      document.documentElement.style.setProperty('--wwd-bg-3-url',`url("${three.filePath}")`);

    }

  }

}
