
export enum BUSINESSES {
  AngioCare = "AngioCare",
  EPS = "EPS",
  MED = "MED",
  Cardiac = "Cardiac",
  M3 = "M3",
  MacroMed = "MacroMed",
  Synapse = "Synapse",
  Tekno = "Tekno"
}
