import { Injectable } from '@angular/core';
import { SPECIALITIES, Speciality } from '../models/speciality';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecialitiesService {

  getSpecialities(): Observable<Speciality[]> {
    return of(SPECIALITIES);
  }

  getSpeciality(index:number): Observable<Speciality> {
    return of(SPECIALITIES[index]);
  }

  constructor() { }
}
