import { Component, OnInit, Input } from '@angular/core';
import { PageComponentAsset } from 'src/app/models/page';

@Component({
  selector: 'app-image-reveal',
  templateUrl: './image-reveal.component.html',
  styleUrls: ['./image-reveal.component.less']
})
export class ImageRevealComponent implements OnInit {

  @Input() pageAssets: PageComponentAsset[];
  image1: any;
  image2: any;

  constructor() { }

  ngOnInit(): void {
  }

  getAltAttribute(code: string){
    if(this.pageAssets)
    {
      let item = this.pageAssets.find(x => x.altAttribute == code);
      return item.filePath;
    }
  }

  getAltTag(code: string){
    if(this.pageAssets)
    {
      let item = this.pageAssets.find(x => x.altAttribute == code);
      return item.altAttribute;
    }
  }

}
