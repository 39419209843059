import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-only',
  templateUrl: './arrow-only.component.html',
  styleUrls: ['./arrow-only.component.less']
})
export class ArrowOnlyComponent implements OnInit {
  @Input() TextColor="white";
  @Input() IsOpening:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
