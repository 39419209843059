import { Injectable } from "@angular/core";

export class Cookie {
  name: string;
}


@Injectable({
  providedIn: "root",
})

export class CookieService {
  Cookies:Cookie[];
  constructor() {
    this.Cookies = [
      {
        name: "GA",
      }
    ]
  }

  acceptAllCookies() {
    for (var x=0; x<this.Cookies.length; x++) {
      this.setCookie(this.Cookies[x].name, "yes");
    }
  }

  rejectAllCookies() {
    for (var x=0; x<this.Cookies.length; x++) {
      this.setCookie(this.Cookies[x].name, "no");
    }
  }

  getValue(name: string) {
    return this.getCookie(name);
  }

  setValue(name: string, value:string) {
    this.setCookie(name, value);
  }

  hasUserSetPreferences() {
    for (var x=0; x<this.Cookies.length;x++) {
      console.log("->", this.getCookie(this.Cookies[x].name));
      if (this.getCookie(this.Cookies[x].name)) {
        return true;
      }
    }
    return false;
  }

  setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 365 days
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie =
      name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  }

  deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
  }
}
