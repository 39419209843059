<!--<div class="padding-container">
  <div class="container-fluid atf atf-init"> 
    <div class="row full-height">
     
      <div class="col-12 col-xl-4 full-height no-left-padding">
        
        
        <div class="center-vertical">
          <h1>What We Do</h1>
          <p [innerHTML]="pageMainComponent?.sectionContent">
          </p>
          <app-arrow-button [Target]="'/suppliers'" [ButtonText]="'Our Suppliers'"></app-arrow-button>
          <a routerLink="/suppliers">
            <div class="next-step">
              <ul class="attention">
                <li>
                  <app-attention-bar></app-attention-bar>
                </li>
                <li>
                  <p>Our Suppliers</p>
                </li>
              </ul>
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-xl-8 full-height">
        <div class="desktop full-height">
          <app-what-we-do-desktop [pageComponent]="pageMainComponent"></app-what-we-do-desktop>
        </div>
        <div class="mobile">

        </div>
      </div>
    </div>
  </div>
  
</div>-->
<div class="padding-container">
  <div class="atf atf-init what-we-do-ctr">
    <div class="row full-height">
      <div class="col-12 col-md-5 full-height left" id="left">
        <h1>{{pageMainComponent?.sectionTitle}}</h1>
          <p [innerHTML]="pageMainComponent?.sectionContent">
          </p>
      </div>
      <div class="col-12 col-md-7 full-height" id="right">
        <div class="full-height">
          <div class="row full-height">
            <div class="col full-height" style="padding-left: 0px;">
              <app-what-we-do-links-l [pageComponent]="pageMainComponent"></app-what-we-do-links-l>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\what-we-do\'>What We Do</a>&nbsp;&nbsp;|&nbsp;&nbsp;Overview'">
</app-breadcrumb>