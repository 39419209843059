<div class="padding-container">
  <div class="atf atf-init ourpeeps">
    <div class="row full-height">
      <div class="col-12 col-lg-5 full-height">
        <h1 class="mdd">{{pageMainComponent?.sectionTitle}}</h1>
        <p class="mddcopy" [innerHTML]="pageMainComponent?.sectionContent">
        </p>
        <div class="next-step">
          <a routerLink="/specialities">
            <ul class="attention">
              <li>
                <app-attention-bar></app-attention-bar>
              </li>
              <li><p class="nowrap">Specialities</p></li>
            </ul>
          </a>
        </div>
      </div>
      <div class="col-12 col-lg-7 full-height right desktop">
        <div class="top">
          <app-mdd-top [pageComponent]="pageTopComponent"></app-mdd-top>
        </div>
        <div class="bottom">
          <div class="bottom-container wwd">
            <app-mdd-bottom [pageComponent]="pageBottomComponent"></app-mdd-bottom>
          </div>
        </div>

      </div>

    </div>

  </div>

</div>
<div class="mobile">
  <app-mdd-mobile [pageTopComponent]="pageTopComponent" [pageBottomComponent]="pageBottomComponent" [pageTopComponentMobile]="pageTopComponentMobile"></app-mdd-mobile>
</div>
<app-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\what-we-do\'>What We Do</a>&nbsp;&nbsp;|&nbsp;&nbsp;Medical Device Distribution'"></app-breadcrumb>
