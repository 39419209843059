import { Component, OnInit } from '@angular/core';
import { Speciality, ContentGroup, ContentItem } from 'src/app/models/speciality';
import { SpecialitiesService } from 'src/app/services/specialities.service';
import { PageService } from 'src/app/service/page.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-specialities',
  templateUrl: './specialities.component.html',
  styleUrls: ['./specialities.component.less']
})
export class SpecialitiesComponent implements OnInit {
  Specialities: Speciality[];
  CurrentSpeciality:Speciality;

  code: string = "SPL001";
  contentGroupSpeciality: ContentGroup;
  currentContent: ContentItem;


  constructor(private specialitiesService:SpecialitiesService,
    private _service: PageService,
    private router: Router,
    private route:ActivatedRoute,
    private titleService: Title) {
      this.titleService.setTitle("Specialities | Uniphar Medtech");
    }

  ngOnInit(): void {
    this.specialitiesService.getSpecialities().subscribe(
      specialities => this.Specialities = specialities
    );

    this._service.getContentByCode(this.code).subscribe(
      specialities => {
        this.contentGroupSpeciality = specialities
        this.contentGroupSpeciality.contents = this.contentGroupSpeciality.contents.sort(function(a, b) {
          return a.index - b.index;
        });
      }
    );
  }

  SetSpeciality(index:number): void {
    this.CurrentSpeciality = this.Specialities[index];
    //console.log(this.CurrentProfilePicture);
  }

  getDataListCollection(){
    if(this.contentGroupSpeciality)
    {
      return this.contentGroupSpeciality.contents;
    }
  }

  SetSpecialityV2(item : ContentItem) : void
  {
    this.currentContent = item;
    console.log(item.description);
  }

  onView(item : ContentItem){
    this.router.navigate([item.contentUrl, { id: item.id} ], { relativeTo: this.route, state: {selectedItem: item} });
  }


}
