<nav id="sidebar-main">
  <div class="navicon " (click)="ToggleClass()" [class.is-open]="isMenuOpen">
    <svg id="hamburger" class="d-block d-lg-none" width="30" height="23">
      <line id="line-top" x1="0" x2="30" y1="2" y2="2" style="stroke: black; stroke-width: 1px" />
      <line id="line-middle" x1="0" x2="30" y1="12" y2="12" style="stroke: black; stroke-width: 1px" />
      <line id="line-bottom" x1="0" x2="30" y1="22" y2="22" style="stroke: black; stroke-width: 1px" />
    </svg>
  </div>
  <div class="naviconmobile" (click)="ToggleClassMobi()" [class.is-mobi-open]="isMobiMenuOpen">
    <svg id="hamburger" width="30" height="23">
      <line id="line-top" x1="0" x2="30" y1="2" y2="2" style="stroke: black; stroke-width: 1px" />
      <line id="line-middle" x1="0" x2="30" y1="12" y2="12" style="stroke: black; stroke-width: 1px" />
      <line id="line-bottom" x1="0" x2="30" y1="22" y2="22" style="stroke: black; stroke-width: 1px" />
    </svg>
  </div>
  <a href="/">
    <div class="logo">
    </div>
  </a>
</nav>
<section id="page-content">
  <app-home-menu></app-home-menu>
  <div class="spacer-desktop d-none d-lg-block"></div>
  <router-outlet></router-outlet>
  <div class="container-fluid">
    <div class="divider"></div>
    <app-footer-links></app-footer-links>
  </div>
  <app-footer-map (onCookies)="showPopup()"></app-footer-map>
</section>
<section id="desktop-menu" [class.is-open]="isMenuOpen">
  <div class="container-fluid">
    <div class="row">
      <div class="col-3">
        <a href="/"><img src="../assets/images/logo.png" /></a>
      </div>
      <div class="col-9">
        <app-desktop-menu></app-desktop-menu>
      </div>
    </div>
  </div>
</section>
<section id="mobile-menu" [class.is-mobi-open]="isMobiMenuOpen">
  <div class="row">
    <div class="col">
      <app-mobil-menu></app-mobil-menu>
    </div>
  </div>
</section>
<section id="cookiebanner" [class.show]="hasUserSetPreferences===false">
  <img class="close" src="../assets/images/close.png" (click)="acceptAll()" />
  <div class="container">
    <div class="row">
      <div class="col">
        <h4>Notice</h4>
        <p>We and selected partners, use cookies or similar technologies as specified in the <a href="#" (click)="showPopup()">cookie policy</a>.
        You can consent to the use of such technologies by closing this notice or by continuing to browse otherwise.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm top-padding">
        <button (click)="showPopup()">Learn more and customize</button>
      </div>
      <div class="col-12 col-sm align-right  top-padding size-up">
        <button (click)="rejectAll()">Reject</button>
        <button (click)="acceptAll()">Accept</button>
      </div>
    </div>
  </div>
</section>
<section id="cookiepopup">
  <section class="overlay" (click)="hidePopup()"></section>
  <div class="modalpopup">
    <div class="back" (click)="hidePopup()">
      <ul>
        <li>
          <div class="arrow">
            <app-arrow-only [TextColor]="'#000'"></app-arrow-only>
          </div>
        </li>
        <li>
          <p>Back</p>
        </li>
      </ul>
    </div>
    <h4>Cookie Policy</h4>
    <div class="cookie-scroll">
      <p>This area is dedicated to your cookie policy. You can add as much detail as possible, as this container is scrollable.</p>
      <p>We will also allow the user to have fine-grain control over the cookies they allow by interacting below.</p>

      <h4>Cookie Preferences</h4>
      <p>You may review and change your choices at any time.</p>
      <br />
      <ul>
        <li>Google Analytics</li>
        <li class="align-right">
          <label class="switch">
            <input type="checkbox" [checked]="is_GA_Allowed" (change)="changeGA($event)">
            <span class="slider round"></span>
          </label>
        </li>
      </ul>
      <p class="cookieservice">Google Analytics provides valuable insights that help us improve on the user experience for this website.</p>
      <hr />
    </div>
    <div class="actions">
      <button (click)="rejectAll()">Reject All</button>
      <button (click)="acceptAll()">Accept All</button>
    </div>
  </div>
</section>
