<div class="outer" #outer>
    <div id="map-container">
    </div>
    <div class="sentence">
        <p class="gh_text_light gh_center_in_container gh_no_margin_no_padding gh_font_small">
            Click on the company logos below to navigate the map.
        </p>
    </div>
</div>
<div class="companies">
    <div class="company-container">
        <div class="row" style="padding-bottom: 20px;">
            <div style="padding-left: 20px;">
                <h5 style="cursor: pointer; " (click)="setFilter(1)">All Locations</h5>
                <div class="divider" *ngIf="filter == 1"></div>
            </div>
            <div style="padding-left: 20px;">
                <h5 style="cursor: pointer;" (click)="setFilter(2)">United Kingdom</h5>
                <div class="divider" *ngIf="filter == 2"></div>
            </div>
            <div style="padding-left: 20px;">
                <h5 style="cursor: pointer;" (click)="setFilter(3)">Europe</h5>
                <div class="divider" *ngIf="filter == 3"></div>
            </div>
        </div>
        <div class="row" *ngIf="filter == 1">

            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/logo-f.png"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" alt="Uniphar Medtech" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Uniphar Medtech (IE)
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/cardiac.png" alt="Cardiac Services"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Cardiac Services (IE)
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/synapse.png" alt="Synapse Medical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Synapse Medical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/med.png" alt="M.E.D Surgical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">M.E.D Surgical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
            </div>

            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/tekno.png" alt="Tekno Surgical"
                            (click)="setLocationByCoords(-6.412213, 53.349093)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.412213, 53.349093)">Tekno Surgical</p>
                        <p class="address">10 Fonthill Business Park, Dublin 22, D22 PP58, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/m3.png" alt="M3 Medical"
                            (click)="setLocationByCoords(-6.24889, 53.33306)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.24889, 53.33306)">M3 Medical</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/cardiac.png" alt="Cardiac Services"
                            (click)="setLocationByCoords(-5.959121, 54.579127)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-5.959121, 54.579127)">Cardiac Services (NI)</p>
                        <p class="address">6 Wildflower Way, Boucher Road, Belfast, BT12 6TA, Northern Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/logo-f.png" alt="Uniphar Medtech"
                            (click)="setLocationByCoords(-0.853784, 52.223946)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-0.853784, 52.223946)">Uniphar Medtech (UK)</p>
                        <p class="address">6 Weddell Way, Brackmills Industrial Estate, Northampton, Northamptonshire,
                            NN4 7HS, United Kingdom.</p>
                    </div>
                </div>
            </div>

            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/cardiac.png" alt="Cardiac Services"
                            (click)="setLocationByCoords(-2.132609, 53.337945)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-2.132609, 53.337945)">Cardiac Services (UK)</p>
                        <p class="address">Europa House, Adlington Business Park, Adlington, Macclesfield, SK10 4NL,
                            United Kingdom.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/macromed.png" alt="MacroMed"
                            (click)="setLocationByCoords(-2.388735, 53.771042)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-2.388735, 53.771042)">MacroMed</p>
                        <p class="address">4 Petre Road, Clayton Business Park, Clayton Le Moors, Accrington, Lancs, BB5
                            5JB, United Kingdom.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/angiocare.png" alt="Angiocare"
                            (click)="setLocationByCoords(5.308188, 52.221574)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(5.308188, 52.221574)">Angiocare</p>
                        <p class="address">Spacelab 3, 3824 MR Amersfoort, Holland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/eps.png" alt="EPS Vascular"
                            (click)="setLocationByCoords(12.579965, 56.142226)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(12.579965, 56.142226)">EPS Vascular (SE)</p>
                        <p class="address">Hamnplanen 24, SE-263 61 Viken, Sweden.</p>
                    </div>
                </div>
            </div>

            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/CM_LOGO.png" alt="CoRRect Medical"
                            (click)="setLocationByCoords(11.58542,48.01996)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8 ">
                        <p class="title" (click)="setLocationByCoords(11.58542,48.01996)">CoRRect Medical</p>
                        <p class="address">Bahnhofstr. 32 82041 Oberhaching, Germany</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12 invisible">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/cardiac.png" alt="Cardiac Services"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Cardiac Services (IE)
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12 invisible">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/synapse.png" alt="Synapse Medical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Synapse Medical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12 invisible">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/synapse.png" alt="Synapse Medical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Synapse Medical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="filter == 2">
            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/logo-f.png" alt="Uniphar Medtech"
                            (click)="setLocationByCoords(-0.853784, 52.223946)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-0.853784, 52.223946)">Uniphar Medtech (UK)</p>
                        <p class="address">6 Weddell Way, Brackmills Industrial Estate, Northampton, Northamptonshire,
                            NN4 7HS, United Kingdom.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/cardiac.png" alt="Cardiac Services"
                            (click)="setLocationByCoords(-2.132609, 53.337945)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-2.132609, 53.337945)">Cardiac Services (UK)</p>
                        <p class="address">Europa House, Adlington Business Park, Adlington, Macclesfield, SK10 4NL,
                            United Kingdom.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/macromed.png" alt="MAcroMed"
                            (click)="setLocationByCoords(-2.388735, 53.771042)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-2.388735, 53.771042)">MacroMed</p>
                        <p class="address">4 Petre Road, Clayton Business Park, Clayton Le Moors, Accrington, Lancs, BB5
                            5JB, United Kingdom.</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="row" *ngIf="filter == 3">

            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/logo-f.png" alt="Uniphar Medtech"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Uniphar Medtech (IE)
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/cardiac.png" alt="Cardiac Services"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Cardiac Services (IE)
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/synapse.png" alt="Synapse Medical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Synapse Medical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/med.png" alt="M.E.D Surgical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">M.E.D Surgical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
            </div>

            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/tekno.png" alt="Tekno Surgical"
                            (click)="setLocationByCoords(-6.412213, 53.349093)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.412213, 53.349093)">Tekno Surgical</p>
                        <p class="address">10 Fonthill Business Park, Dublin 22, D22 PP58, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/m3.png" alt="M3 Medical"
                            (click)="setLocationByCoords(-6.24889, 53.33306)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.24889, 53.33306)">M3 Medical</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/cardiac.png" alt="Cardiac Services"
                            (click)="setLocationByCoords(-5.959121, 54.579127)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-5.959121, 54.579127)">Cardiac Services (NI)</p>
                        <p class="address">6 Wildflower Way, Boucher Road, Belfast, BT12 6TA, Northern Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/angiocare.png" alt="Angiocare"
                            (click)="setLocationByCoords(5.308188, 52.221574)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(5.308188, 52.221574)">Angiocare</p>
                        <p class="address">Spacelab 3, 3824 MR Amersfoort, Holland.</p>
                    </div>
                </div>
            </div>

            <div class="row brand-row">
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/CM_LOGO.png" alt="CoRRect Medical"
                            (click)="setLocationByCoords(11.58542,48.01996)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8 ">
                        <p class="title" (click)="setLocationByCoords(11.58542,48.01996)">CoRRect Medical</p>
                        <p class="address">Bahnhofstr. 32 82041 Oberhaching, Germany</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/eps.png" alt="EPC Vascular"
                            (click)="setLocationByCoords(12.579965, 56.142226)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(12.579965, 56.142226)">EPS Vascular (SE)</p>
                        <p class="address">Hamnplanen 24, SE-263 61 Viken, Sweden.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12 invisible">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/synapse.png" alt="Synapse Medical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Synapse Medical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
                <div class="item col-lg-3 col-sm-12 invisible">
                    <div class="item-image col-lg-6 col-sm-3 col-xs-4">
                        <img src="../../../assets/images/footer/synapse.png" alt="Synapse Medical"
                            (click)="setLocationByCoords(-6.317665, 53.399865)" />
                    </div>
                    <div class="item-text col-lg-6 col-sm-9 col-xs-8">
                        <p class="title" (click)="setLocationByCoords(-6.317665, 53.399865)">Synapse Medical
                            Head Office</p>
                        <p class="address">Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11, Ireland.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="terms">
    <p><a href="../../../assets/documents/terms.pdf" target="_blank">Terms</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="../../../assets/documents/privacy.pdf" target="_blank">Privacy
            Policy</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="../../../assets/documents/modern-slavery.pdf" target="_bl">Modern
            Slavery</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a (click)="ShowPrefs()">Cookies</a></p>
</div>