import { Component, OnInit } from '@angular/core';
import { ContentGroup, ContentItem } from 'src/app/models/speciality';
import { PageService } from 'src/app/service/page.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.less']
})
export class SuppliersComponent implements OnInit {
  state:number = 0;
  code: string = "SUP001";
  contentGroupSpeciality: ContentGroup;
  length = 0;
  pages = 1;
  contentsAll: ContentItem[];
  contentsPagged: ContentItem[];
  autoTimer;

  constructor(private _service: PageService, private titleService: Title) {
    this.titleService.setTitle("Our Suppliers | Uniphar Medtech");
   }

  ngOnInit(): void {
    this._service.getContentByCode(this.code).subscribe(
      specialities => {
        this.contentGroupSpeciality = specialities
        this.contentGroupSpeciality.contents = this.contentGroupSpeciality.contents.sort(function(a, b) {
          return a.index - b.index;
        });
        this.length = this.contentGroupSpeciality.contents.length ?? 0;
        this.contentsAll = this.contentGroupSpeciality.contents;
        this.contentsPagged = this.contentsAll.slice(0, 30);
        //calculate number of pages:
        if(this.length != 0)
        {
          this.pages = Math.ceil( this.length/30 );
        }
        
        this.startTimer();
      }
    );
  }

  getDataListCollection(){
    if(this.contentGroupSpeciality)
    {
      return this.contentGroupSpeciality.contents;
    }
  }

  SetStateId(x:number) {
    console.log("State:",x)
    this.stopTimer();
    this.state = x;
    let index = x*30;
    this.contentsPagged = this.contentsAll.slice(index, index+30);
    this.startTimer();
  }

  startTimer(): void {
    this.autoTimer = window.setInterval(() => {
      this.state = this.getNextStateUp();
      this.SetStateId(this.state);
    }, 8000);
  }

  stopTimer(): void {
    window.clearInterval(this.autoTimer);
  }

  getNextStateUp(): number {
    var nextState: number;
    nextState = this.state + 1;
    if (nextState == this.pages) nextState = 0;
    return nextState;
  }

  getNextStateDown(): number {
    var nextState: number;
    nextState = this.state - 1;
    if (nextState < 0) nextState = this.pages-1;
    return nextState;
  }


}
