import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injectable } from '@angular/core';
// Import the library module
import { AngularResizedEventModule } from 'angular-resize-event';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { ImageRevealComponent } from './components/image-reveal/image-reveal.component';
import { FooterLinksComponent } from './components/footer-links/footer-links.component';
import { FooterMapComponent } from './components/footer-map/footer-map.component';
import { VisionComponent } from './views/vision/vision.component';
import { OurVisionComponent } from './components/our-vision/our-vision.component';
import { OurMissionComponent } from './components/our-mission/our-mission.component';
import { SocialComponent } from './views/social/social.component';
import { PagerComponent } from './components/pager/pager.component';
import { CopyTickerComponent } from './components/copy-ticker/copy-ticker.component';
import { SocialSlidesComponent } from './components/social-slides/social-slides.component';
import { CultureComponent } from './views/culture/culture.component';
import { OurVisionMobileComponent } from './components/our-vision-mobile/our-vision-mobile.component';
import { OurMissionMobileComponent } from './components/our-mission-mobile/our-mission-mobile.component';
import { SocialSlidesMobileComponent } from './components/social-slides-mobile/social-slides-mobile.component';
import { CopyTickerMobileComponent } from './components/copy-ticker-mobile/copy-ticker-mobile.component';
import { DesktopMenuComponent } from './components/desktop-menu/desktop-menu.component';
import { MobilMenuComponent } from './components/mobil-menu/mobil-menu.component';
import { OverviewComponent } from './views/what-we-do/overview/overview.component';
import { WhatWeDoDesktopComponent } from './components/what-we-do-desktop/what-we-do-desktop.component';
import { MedicalDeviceDistributionComponent } from './views/what-we-do/medical-device-distribution/medical-device-distribution.component';
import { MddTopComponent } from './components/mdd/mdd-top/mdd-top.component';
import { MddBottomComponent } from './components/mdd/mdd-bottom/mdd-bottom.component';
import { SuppliersComponent } from './views/what-we-do/suppliers/suppliers.component';
import { SpecialitiesComponent } from './views/what-we-do/specialities/specialities.component';
import { SpecialitySummaryComponent } from './components/specialities/speciality-summary/speciality-summary.component';
import { MddMobileComponent } from './components/mdd/mdd-mobile/mdd-mobile.component';
import { PercentageBlockTextComponent } from './components/specialities/percentage-block-text/percentage-block-text.component';
import { PercentageBlockHorizontalComponent } from './components/specialities/percentage-block-horizontal/percentage-block-horizontal.component';
import { InlineBreadcrumbComponent } from './components/inline-breadcrumb/inline-breadcrumb.component';
import { ContactUsComponent } from './views/contact/contact-us/contact-us.component';
import { CallingCodeComponent } from './components/calling-code/calling-code.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';


@Injectable()
export class HammerConfig extends HammerGestureConfig {
     overrides = <any>{
          'pinch': { enable: false },
          'rotate': { enable: false }
     }
}

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LatestComponent } from './views/news/latest/latest.component';
import { ArticleComponent } from './views/news/article/article.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { WeDoMoreIntroComponent } from './views/we-do-more/we-do-more-intro/we-do-more-intro.component';
import { WeDoMoreLinksLComponent } from './components/we-do-more-links/l/we-do-more-links-l/we-do-more-links-l.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { WeDoMoreValueChainComponent } from './views/we-do-more/we-do-more-value-chain/we-do-more-value-chain.component';
import { WhatWeDoLinksLComponent } from './components/what-we-do-links/l/what-we-do-links-l/what-we-do-links-l.component';
import { SharedModuleModule } from './shared-module/shared-module.module';


@NgModule({
     declarations: [
          AppComponent,
          AboutUsComponent,
          ImageRevealComponent,
          FooterLinksComponent,
          FooterMapComponent,
          VisionComponent,
          OurVisionComponent,
          OurMissionComponent,
          SocialComponent,
          PagerComponent,
          CopyTickerComponent,
          SocialSlidesComponent,
          CultureComponent,
          OurVisionMobileComponent,
          OurMissionMobileComponent,
          SocialSlidesMobileComponent,
          CopyTickerMobileComponent,
          DesktopMenuComponent,
          MobilMenuComponent,
          OverviewComponent,
          WhatWeDoDesktopComponent,
          MedicalDeviceDistributionComponent,
          MddTopComponent,
          MddBottomComponent,
          SuppliersComponent,
          SpecialitiesComponent,
          SpecialitySummaryComponent,
          MddMobileComponent,
          PercentageBlockTextComponent,
          PercentageBlockHorizontalComponent,
          InlineBreadcrumbComponent,
          ContactUsComponent,
          CallingCodeComponent,
          LatestComponent,
          ArticleComponent,
          HomeMenuComponent,
          WeDoMoreIntroComponent,
          WeDoMoreLinksLComponent,
          WeDoMoreValueChainComponent,
          WhatWeDoLinksLComponent,
     ],
     imports: [
          BrowserModule,
          BrowserAnimationsModule,
          AppRoutingModule,
          HammerModule,
          // Specify AngularResizedEventModule library as an import
          AngularResizedEventModule,
          FormsModule,
          ReactiveFormsModule,
          HttpClientModule,
          FontAwesomeModule,
          //NgxExtendedPdfViewerModule,
          ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
          SharedModuleModule
     ],
     providers: [{
          provide: HAMMER_GESTURE_CONFIG,
          useClass: HammerConfig
     }],
     bootstrap: [AppComponent]
})
export class AppModule { }
