import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
    AbstractControl,
} from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { ContactService } from "src/app/services/contact.service";

@Component({
    selector: "app-contact-us",
    templateUrl: "./contact-us.component.html",
    styleUrls: ["./contact-us.component.less"],
})
export class ContactUsComponent implements OnInit {
    contactForm: FormGroup;
    fullname: AbstractControl;
    email: AbstractControl;
    countrycode: AbstractControl;
    contactnumber: AbstractControl;
    interest: AbstractControl;
    contact_you: AbstractControl;
    message: AbstractControl;

    isSubmitted: boolean = false;
    formHasErrors: boolean = false;
    formErrors: string[] = [];

    constructor(fb: FormBuilder, private titleService: Title, private contactService: ContactService) {
        this.titleService.setTitle("Contact Us | Uniphar Medtech");
        this.contactForm = fb.group({
            fullname: ["", [Validators.required, Validators.minLength(4)]],
            email: ["", [Validators.required]],
            countrycode: ["", [Validators.required]],
            contactnumber: ["", [Validators.required]],
            interest: ["", [Validators.required]],
            contact_you: ["", [Validators.required]],
            message: ["", [Validators.required]],
        });

        this.fullname = this.contactForm.controls["fullname"];
        this.email = this.contactForm.controls["email"];
        this.countrycode = this.contactForm.controls["countrycode"];
        this.contactnumber = this.contactForm.controls["contactnumber"];
        this.interest = this.contactForm.controls["interest"];
        this.contact_you = this.contactForm.controls["contact_you"];
        this.message = this.contactForm.controls["message"];
    }

    ngOnInit(): void {
    }

    OnEmailKeyUp() {
        if (this.contactForm.controls['email'].value.length < 2) {
            this.contactForm.get('countrycode').reset();
            this.contactForm.get('contactnumber').reset();
            this.contactForm.get('interest').reset();
            this.contactForm.get('contact_you').reset();
            this.contactForm.get('message').reset();
        }
    }

    onSubmit(value) {
        this.formHasErrors = false;
        this.formErrors = [];
        if (this.contactForm.valid) {
            this.isSubmitted = true;
            console.log("you submitted: ", value);
            this.contactService.submitContactEnquiry({
                contactMethod: value.contact_you,
                countryCode: value.countrycode,
                countryNumber: value.contactnumber,
                email: value.email,
                fullName: value.fullname,
                interest: value.interest,
                message: value.message
            }).subscribe(data => {
                console.log(data);
            });
            console.log(value.contactnumber);
            /*
            contact_you: "call"
            contactnumber: "+639776412290"
            countrycode: "63"
            email: "andre1678@gmail.com"
            fullname: "Andre Pretorius"
            interest: "supplier"
            */
        } else {
            this.formHasErrors = true;

            if (!this.fullname.valid) this.formErrors.push("Please enter a valid name.");
            if (!this.email.valid) this.formErrors.push("Please enter a valid email address.");
            if (!this.countrycode.valid || !this.contactnumber.valid) this.formErrors.push("Please enter a valid contact number.");
            if (!this.interest.valid) this.formErrors.push("Please tell us what you are interested in.");
            if (!this.contact_you.valid) this.formErrors.push("Please let us know how you would like to be contacted.");
            debugger;
        }
    }
}
