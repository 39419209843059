<div class="missioncontainer">
  <div class="top-image">
    <img [src]="mission1 || '../../../assets/images/Mission/mission-1.jpg'" />
  </div>
  <img class="mission-2" [src]="mission2 || '../../../assets/images/Mission/mission-2.jpg'" />
  <a routerLink="/contact">
    <div class="speak">
      <p>Speak to us</p>
      <img src="../../../assets/images/white-right.png" />
    </div>
  </a>
  <img class="mission-3" [src]="mission3 || '../../../assets/images/Mission/mission-3.jpg'" />
</div>
