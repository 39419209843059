import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import { GeoPoint } from "src/app/models/geo";
import { Location } from "src/app/models/location";

@Component({
  selector: "app-footer-map",
  templateUrl: "./footer-map.component.html",
  styleUrls: ["./footer-map.component.less"],
})
export class FooterMapComponent implements OnInit, AfterViewInit {
  @Output() onCookies:EventEmitter<null>;
  @ViewChild("outer") outer: ElementRef;
  map: mapboxgl.Map;
  style = "mapbox://styles/andreliveshere/ck708lh020q6t1jqo3nk1ex60";
  lat = 52.21072;
  lng = 2.118862;
  filter = 1;

  mapLoaded: boolean;

  GeoPoints: GeoPoint[] = [
    {
      type: "Feature",
      address: "Unit 3 Northern Cross Business Park Finglas, Dublin, D11 WY11",
      name:
        "SISK Healthcare (IE), Cardiac Services, Synapse Medical, M.E.D. Surgical",
      geometry: {
        type: "Point",
        coordinates: [-6.317665, 53.399865],
      },
    },
    {
      type: "Feature",
      address:
        "6 Weddell Way Brackmills Industrial Estate Northampton Northamptonshire NN4 7HS",
      name: "SISK Healthcare (UK)",
      geometry: {
        type: "Point",
        coordinates: [-0.853784, 52.223946],
      },
    },
    {
      type: "Feature",
      address: "10 Fonthill Business Park Dublin 22 D22 PP58",
      name: "Tekno Surgical",
      geometry: {
        type: "Point",
        coordinates: [-6.412213, 53.349093],
      },
    },
    {
      type: "Feature",
      address: "6 Wildflower Way Boucher Road Belfast BT12 6TA",
      name: "Cardiac Services (NI)",
      geometry: {
        type: "Point",
        coordinates: [-5.959121, 54.579127],
      },
    },
    {
      type: "Feature",
      address:
        "Europa House Adlington Business Park Adlington Macclesfield SK10 4NL",
      name: "Cardiac Services (UK)",
      geometry: {
        type: "Point",
        coordinates: [-2.132609, 53.337945],
      },
    },
    {
      type: "Feature",
      address: "Unit 3, Northern Cross Business Park, Finglas, Dublin, D11 WY11",
      name: "M3 Medical",
      geometry: {
        type: "Point",
        coordinates: [-6.24889, 53.33306],
      },
    },
    {
      type: "Feature",
      address:
        "4 Petre Road, Clayton Business Park, Clayton Le Moors, Accrington, Lancs BB5 5JB",
      name: "MacroMed",
      geometry: {
        type: "Point",
        coordinates: [-2.388735, 53.771042],
      },
    },
    {
      type: "Feature",
      address: "Eemweg 31-21 3755 LC Eemnes, Netherlands",
      name: "Angiocare",
      geometry: {
        type: "Point",
        coordinates: [5.308188, 52.221574],
      },
    },
    {
      type: "Feature",
      address: "63 62 Viken, Sweden",
      name: "EPS Vascular (SE)",
      geometry: {
        type: "Point",
        coordinates: [12.579965, 56.142226],
      },
    },
    {
      type: "Feature",
      address: "Bahnhofstr. 32 82041 Oberhaching, Germany",
      name: "CoRRect Medical GmbH",
      geometry: {
        type: "Point",
        coordinates: [11.58542,48.01996],
      },
    }/*,
    {
        type: "Feature",
        address: "Ireland",
        name: "Ireland",
        geometry: {
          type: "Point",
          coordinates: [7.3055,53.7798],
        },
      },
      {
        type: "Feature",
        address: "UK",
        name: "UK",
        geometry: {
          type: "Point",
          coordinates: [1.1743,52.3555],
        },
      },
      {
        type: "Feature",
        address: "Holland",
        name: "Holland",
        geometry: {
          type: "Point",
          coordinates: [5.2913,52.1326],
        },
      },
      {
        type: "Feature",
        address: "Belgium",
        name: "Belgium",
        geometry: {
          type: "Point",
          coordinates: [4.4699,50.5039],
        },
      },
      {
        type: "Feature",
        address: "Luxembourg",
        name: "Luxembourg",
        geometry: {
          type: "Point",
          coordinates: [6.1296,49.8153],
        },
      },
      {
        type: "Feature",
        address: "Sweden",
        name: "Sweden",
        geometry: {
          type: "Point",
          coordinates: [18.6435,60.1282],
        },
      },
      {
        type: "Feature",
        address: "Norway",
        name: "Norway",
        geometry: {
          type: "Point",
          coordinates: [8.4689,60.4720],
        },
      },
      {
        type: "Feature",
        address: "Finland",
        name: "Finland",
        geometry: {
          type: "Point",
          coordinates: [25.7482,61.9241],
        },
      },
      {
        type: "Feature",
        address: "Iceland",
        name: "Iceland",
        geometry: {
          type: "Point",
          coordinates: [-19.0208,64.9631],
        },
      },
      {
        type: "Feature",
        address: "Latvia",
        name: "Latvia",
        geometry: {
          type: "Point",
          coordinates: [24.6032,56.8796],
        },
      },
      {
        type: "Feature",
        address: "Lithuania",
        name: "Lithuania",
        geometry: {
          type: "Point",
          coordinates: [23.8813,55.1694],
        },
      },
      {
        type: "Feature",
        address: "Estonia",
        name: "Estonia",
        geometry: {
          type: "Point",
          coordinates: [25.0136,58.5953],
        },
      },
      {
        type: "Feature",
        address: "Switzerland",
        name: "Switzerland",
        geometry: {
          type: "Point",
          coordinates: [8.2275,46.8182],
        },
      },
      {
        type: "Feature",
        address: "Italy",
        name: "Italy",
        geometry: {
          type: "Point",
          coordinates: [12.5674,41.8719],
        },
      },
      {
        type: "Feature",
        address: "Turkey",
        name: "Turkey",
        geometry: {
          type: "Point",
          coordinates: [35.2433,38.9637],
        },
      },
      {
        type: "Feature",
        address: "Hungary",
        name: "Hungary",
        geometry: {
          type: "Point",
          coordinates: [19.5033,47.1625],
        },
      },
      {
        type: "Feature",
        address: "Romania",
        name: "Romania",
        geometry: {
          type: "Point",
          coordinates: [24.9668,45.9432],
        },
      },
      {
        type: "Feature",
        address: "Bulgaria",
        name: "Bulgaria",
        geometry: {
          type: "Point",
          coordinates: [25.4858,42.7339],
        },
      },*/
  ];

  constructor() {
    this.mapLoaded = false;
    this.onCookies = new EventEmitter();
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.initializeMap();
    }, 500);
  }

  ShowPrefs() {
    this.onCookies.emit();
  }

  ngOnInit(): void {}

  initializeMap() {
    (mapboxgl as typeof mapboxgl).accessToken =
      "pk.eyJ1IjoiYW5kcmVsaXZlc2hlcmUiLCJhIjoiY2s3MDNoZHJzMWZlZTNucDVnNTlmeTZkeiJ9.cxxK8FjGItmlfOwrlnM6JA"; //environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: "map-container",
      style: this.style,
      zoom: 4,
      center: [this.lng, this.lat],
      scrollZoom: false,
      logoPosition: "top-left",
    });
    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl(), "top-right");

    this.map.on("load", this.loadLayers.bind(this));
  }

  loadLayers(): void {
    this.mapLoaded = true;
    //console.log(this.stores);
    var gs: any = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: [],
    };

    this.GeoPoints.map((result) => {
      gs.features.push({
        type: "Feature",
        properties: {
          id: 0,
          address: result.address,
          name: result.name,
        },
        geometry: {
          type: "Point",
          coordinates: result.geometry.coordinates,
        },
      });
      var el = document.createElement("div");
      el.className = "marker";
      el.style.width = "20px";
      el.style.height = "20px";
      var marker = new mapboxgl.Marker(el)
        .setLngLat([
          result.geometry.coordinates[0],
          result.geometry.coordinates[1],
        ])
        .addTo(this.map);
    });

    //if (this.map.getLayer("clusters")) this.map.removeLayer("clusters");
    if (this.map.getLayer("cluster-count"))
      this.map.removeLayer("cluster-count");
    //if (this.map.getLayer("unclustered-point")) this.map.removeLayer("unclustered-point");
    //if (this.map.getSource("storelocations")) this.map.removeSource("storelocations");

    this.map.addSource("geolocations", {
      type: "geojson",
      data: gs,
      cluster: false,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    }); /*

    this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'storelocations',
        filter: ['has', 'point_count'],

        paint: {
            'circle-color': '#012169',
            'circle-radius': 12,
            'circle-stroke-width': 5,
            'circle-stroke-color': '#FBE122'
        }
    });*/

    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "geolocations",
      //filter: ['has', 'point_count'],
      layout: {
        "text-field": ["get", "name"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
        "text-offset": [0, 3],
      },
      paint: {
        "text-color": "#666",
      },
      minzoom: 12,
    });

    /* this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'storelocations',
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': '#012169',
            'circle-radius': 8,
            'circle-stroke-width': 3,
            'circle-stroke-color': '#FBE122'
        },
    });*/

    //this.map.on('click', 'unclustered-point', this.setPopup.bind(this));
  }

  setLocation(location: Location) {
    this.map.flyTo({
      center: [location.lat, location.lng],
      zoom: 13,
      speed: 0.2,
      curve: 3.5,
    });
  }

  setLocationByCoords(lat: number, lng: number) {
    let location = new Location();
    location.lat = lat;
    location.lng = lng;
    this.setLocation(location);
    window.scroll({
      top: this.getOffset(),
      behavior: 'smooth'
  });
  }

  getOffset(): number {
    return (this.outer.nativeElement as HTMLDivElement).offsetTop;
  }
  setFilter(num: number)
  {
    this.filter = num;
  }
}
