<div class="padding-container hero-bg">
    <div class="container-fluid">
        <div class="row usespace">
            <!--<video autoplay muted loop id="myVideo">
                <source src="../../../assets/video/medtech.mp4" type="video/mp4">
            </video>-->
            <div class="col-12 col-md-6 col-lg-7 full-height">
                <div class="center-vertical" *ngIf="pageResource">
                    <!-- <h1>{{logo}}</h1> -->
                    <h1 class="d-lg-block home-heading">{{title}}</h1>
                    <div class="about-us d-none d-lg-block" [innerHTML]="resourceDesktop"></div>

                    <!--<img [src]="logo || '../../../assets/images/about/logo.png'" class="d-lg-none" />-->
                    <div class="about-us d-lg-none" [innerHTML]="resource"></div>

                    <a routerLink="/history">
                        <ul class="attention">

                            <li>
                                <app-attention-bar></app-attention-bar>
                            </li>
                            <li>
                                <p class="nowrap">Our History</p>
                            </li>
                        </ul>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5  d-none d-md-block full-height hero-img">
                <!--<app-image-reveal [pageAssets]="assets"></app-image-reveal>-->
                <img src="../../../assets/images/about/hero-img.png" />
            </div>
        </div>
    </div>
    <img class="footer d-md-none" src="../../../assets/images/about/hero-img-sm.png" />
</div>