import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-copy-ticker',
  templateUrl: './copy-ticker.component.html',
  styleUrls: ['./copy-ticker.component.less']
})
export class CopyTickerComponent implements OnInit {
  @Input() pageComponent: PageComponent[];
  @Input() state:number = 0;

  social1: PageComponent;
  social2: PageComponent;
  social3: PageComponent;
  social4: PageComponent;
  social5: PageComponent;


  constructor() { }

  ngOnInit(): void {
    this.loadComponents();
  }

  ngOnChanges()
  {
    this.loadComponents();
  }

  
  loadComponents()
  {
    if(this.pageComponent)
    {
      this.pageComponent.forEach(element => {
        if(element && element.id == 19)
        {
          this.social1 = element;
        }
        else if(element && element.id == 20)
        {
          this.social2 = element;
        }
        else if(element && element.id == 21)
        {
          this.social3 = element;
        }
        else if(element && element.id == 22)
        {
          this.social4 = element;
        }
        else if(element && element.id == 23)
        {
          this.social5 = element;
        }
      });

    }

  }
}
