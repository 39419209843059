<div class="culture min-atf desktop">
     <div class="padding-container">
          <div class="container-fluid min-atf ">
               <div class="row full-height">
                    <div class="col-8 col-xl full-height">
                         <div class="band">
                              <div class="text">
                                   <app-inline-breadcrumb
                                        [Links]="'<a class=\'bold\'  href=\'\\\'>Home</a>&nbsp;&nbsp;|&nbsp;&nbsp;Our Culture'">
                                   </app-inline-breadcrumb>
                                   <h1>Our Culture</h1>
                                   <h2 class="culture-desktop-text" [innerHTML]="pageMainComponent?.sectionContent">
                                   </h2>
                                   <!-- <p>At Uniphar Medtech, we commit to ensure our employees provide the vital relationship link between our
                businesses,
                our customers and the 'patient on the table'. Our employees champion the service we provide and the
                products we represent.
                Achievement of our customer commitment only comes through the talents and extraordinary didication
                our employees continue to bring to thier jobs each and every day.
              </p>
              <p>At the heart of our inclusive culture is commitment, loyalty, entrepreneurial leadership and employee
                engagement and
                wellbeing. We embrace diversity and promote a culture of equality and dignity, and have endeavouredto
                create an agile,
                innovative and talented workforce where employees thrive.
              </p> -->
                              </div>
                         </div>

                    </div>
                    <div class="col-4 col-xl full-height">
                    </div>

               </div>
               <div class="row">
                    <div class="col" style="padding-bottom: 40px;">
                         <div class="white-banner hide-me">
                              <!--<h1>{{pageSubComponent?.sectionTitle}}</h1><br />-->
                              <h1>I Am Here</h1>
                              <div class="red-divider"></div>
                              <div class="i-am-here">
                                   <div class="copy">
                                        <!--<p class="black-subtext-md" [innerHTML]="pageSubComponent?.sectionContent"></p>-->
                                        <p class="black-subtext-md">
                                             Our people's wellbeing is a priority at Uniphar Medtech which is why we've
                                             introduced
                                             a company mental health programme, 'I Am Here'. The programme provides the
                                             skills to
                                             respond to a call for help and the courage to seek help. This platform is
                                             built around
                                             our pillars of culture: honesty, integrity, respect and appreciation.
                                        </p>
                                        <p class="black-subtext-md">Watch the video to find out more.</p>

                                   </div>
                                   <div>
                                        <img src="../../../assets/images/culture/I_am_here_logo.png" />
                                   </div>

                              </div>
                              <div class="video">
                                   <iframe width="560" height="315" src="https://www.youtube.com/embed/vXDDyZjkIA8"
                                        title="I Am Here | Uniphar Medtech's Mental Health & Wellbeing Programme"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                              </div>
                         </div>
                         <div class="core-values">
                              <h1>Core Values</h1>
                              <div class="core-value-text">
                                   <div class="left">
                                        <p>
                                             We encourage team work and the sharing of knowledge throughout the organisation. Together we are building an environment where people have a voice and feel valued. We want our culture to be the embodiment of everything we do, and how we live our values every day:
                                        </p>
                                        <ul>
                                             <li><strong>We go forward together:</strong> We move forward together as one unified business; leveraging our diverse specialities and fostering a “one team” mindset. We make collaborative decisions, act swiftly and understand perfection is the enemy of progress.</li>
                                             <li><strong>We take our business seriously:</strong> We take compliance and risk seriously; our patients and partners depend on us. Our innovative portfolio of products and services is designed to ensure the best outcomes and satisfaction for our customers. We ‘do the right thing’ for the long term. We strive to uphold and drive our sustainability pillars.</li>
                                             <li><strong>We deliver exceptional results:</strong> We deliver exceptional results for our patients, our customers and out business by prioritising quality products and services, customer satisfaction and commercial success.</li>
                                             
                                        </ul>
                                   </div>
                                   <div class="right">
                                        <div class="thumbs">
                                             <div class="r t">
                                                  <div class="l red w">
                                                       <div class="redoverlay">
                                                            <div class="overlay"></div>
                                                            <img src="../../../assets/images/culture/doctors-1.jpg" />
                                                       </div>
                                                  </div>
                                                  <div class="ri black h">
                                                       <img src="../../../assets/images/culture/chat.jpg" />
                                                  </div>
                                             </div>
                                             <div class="r b">
                                                  <div class="l black h">
                                                       <img src="../../../assets/images/culture/doctors-2.jpg" />
                                                  </div>
                                                  <div class="ri red w">
                                                       <div class="redoverlay">
                                                            <div class="overlay"></div>
                                                            <img src="../../../assets/images/culture/lady.jpg" />
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

     </div>
</div>
<div class="mobile">
     <div class="banner">
          <div class="band">
               <div class="text">
                    <app-inline-breadcrumb
                         [Links]="'<a class=\'bold\'  href=\'\\\'>Home</a>&nbsp;&nbsp;|&nbsp;&nbsp;Our Culture'">
                    </app-inline-breadcrumb>
                    <h1>Our Culture</h1>
                    <h2 class="culture-mobile-text" [innerHTML]="pageMainComponent?.sectionContent"></h2>
               </div>
          </div>
          <div class="indicator"></div>
     </div>
     <div class="copy">
          <!--<p [innerHTML]="pageSubComponent?.sectionContent">
          </p>-->
          <!--<img src="../../../assets/images/culture/footer-1.png" class="wide" />-->
          <!--<h1>I Am Here</h1>
          <div class="i-am-here">
               <div class="left">
                    <p>
                         Our people's wellbeing is a priority at Uniphar Medtech which is why we've
                         introduced
                         a company mental health programme, 'I Am Here'. The programme provides the
                         skills to
                         respond to a call for help and the courage to seek help. This platform is
                         built around
                         our pillars of culture: honesty, integrity, respect and appreciation.
                    </p>
                    <p>Watch the video to find out more.</p>
               </div>
               <div class="right">
                    <img class="iam" src="../../../assets/images/culture/I_am_here_logo.png" />
               </div>
          </div>
          <div class="video">
               <iframe width="560" height="315" src="https://www.youtube.com/embed/vXDDyZjkIA8"
                    title="I Am Here | Uniphar Medtech's Mental Health & Wellbeing Programme"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
          <div class="red-divider"></div>-->
          <div class="core-values">
               <h1>Core Values</h1>
               <div>
                    <p>
                         We encourage team work and the sharing of knowledge throughout the organisation. Together we are building an environment where people have a voice and feel valued. We want our culture to be the embodiment of everything we do, and how we live our values every day:
                    </p>
                    <ul>
                         <li><strong>We go forward together:</strong> We move forward together as one unified business; leveraging our diverse specialities and fostering a “one team” mindset. We make collaborative decisions, act swiftly and understand perfection is the enemy of progress.</li>
                                             <li><strong>We take our business seriously:</strong> We take compliance and risk seriously; our patients and partners depend on us. Our innovative portfolio of products and services is designed to ensure the best outcomes and satisfaction for our customers. We ‘do the right thing’ for the long term. We strive to uphold and drive our sustainability pillars.</li>
                                             <li><strong>We deliver exceptional results:</strong> We deliver exceptional results for our patients, our customers and out business by prioritising quality products and services, customer satisfaction and commercial success.</li>
                    </ul>
               </div>
          </div>
          <div class="thumbs">
               <div class="r t">
                    <div class="l red w">
                         <div class="redoverlay">
                              <div class="overlay"></div>
                              <img src="../../../assets/images/culture/doctors-1.jpg" />
                         </div>
                    </div>
                    <div class="ri black h">
                         <img src="../../../assets/images/culture/chat.jpg" />
                    </div>
               </div>
               <div class="r b">
                    <div class="l black h">
                         <img src="../../../assets/images/culture/doctors-2.jpg" />
                    </div>
                    <div class="ri red w">
                         <div class="redoverlay">
                              <div class="overlay"></div>
                              <img src="../../../assets/images/culture/lady.jpg" />
                         </div>
                    </div>
               </div>
          </div>
     </div>
     
     <div class="next-mobile">
          <a routerLink="/what-we-do">
               <ul class="attention">
                    <li>
                         <app-attention-bar></app-attention-bar>
                    </li>
                    <li>
                         <p>What We Do</p>
                    </li>
               </ul>
          </a>
          <div style="clear:both"></div>
     </div>
     <div style="clear:both"></div>
</div>