<div class="padding">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="top-img-container">
          <img [src]="vision1 || '../../../assets/images/Vision/implant.jpg'" />
          <a routerLink="/careers/testimonials"><div class="testimonial">
            <p>Read Our<br /><span>Testimonial</span></p>
            <img src="../../../assets/images/white-right.png" />
          </div></a>
        </div>
        <div class="bottom-image-container">
          <img [src]="vision2 || '../../../assets/images/Vision/scientist.jpg'" />
        </div>
      </div>
      <div class="col-6">
        <h1 class="vision">Our Vision</h1>
        <p class="ourvisioncopy" *ngIf="!pageComponent">
          Through innovation, ingenuity and dedication to our customers,
          our vision is to be a diversified healthcare company
          focused on providing best-in-class products
          and services which improve patient care and enhance
          the quality of people's lives.
        </p>
        <p class="ourvisioncopy" *ngIf="pageComponent" [innerHTML]="pageComponent.sectionContent">

      </div>
    </div>
  </div>
</div>
