<div class="padding">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="top-img-container">
          <img [src]="mission2 || '../../../assets/images/Mission/mission-2.jpg'" />
        </div>
        <div class="bottom-img-container">
          <img [src]="mission3 || '../../../assets/images/Mission/mission-3.jpg'" />
          <a routerLink="/contact">
            <div class="speak">
              <p>Speak to us</p>
              <img src="../../../assets/images/white-right.png" />
            </div>
          </a>
        </div>
      </div>
      <div class="col-6">
        <h1 class="mission">Our Mission</h1>
        <p class="ourmissioncopy" *ngIf="!pageComponent">
          As a medical device distributor, our mission is lived everyday by providing our clients
          with the highest quality products to improve patient care across Europe. We aim to provide best-in-class
          training and education, utilising the latest innovative technologies whilst supporting our customers
          throughout
          the patient life-cycle. With experienced sales representatives, on-site engineers and customer service agents,
          we pride ourselves on service excellence.
        </p>
        <p class="ourmissioncopy" *ngIf="pageComponent" [innerHTML]="pageComponent.sectionContent">
        </p>
      </div>
    </div>
  </div>
</div>
