<section class="copy mobile-ticker">
  <div class="one" [class.showcopy]="state===0" [innerHTML]="social1?.sectionContent">
  </div>
  <div class="two" [class.showcopy]="state===1" [innerHTML]="social2?.sectionContent">
  </div>
  <div class="three" [class.showcopy]="state===2" [innerHTML]="social3?.sectionContent">
  </div>
  <div class="four" [class.showcopy]="state===3" [innerHTML]="social4?.sectionContent">
  </div>
  <div class="five" [class.showcopy]="state===4" [innerHTML]="social5?.sectionContent">
  </div>
</section>
