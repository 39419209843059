
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Source } from 'webpack-sources';
import { environment } from 'src/environments/environment';
import { Page, Table } from '../models/page';
import { Injectable } from '@angular/core';
import { ContentGroup } from '../models/speciality';

@Injectable({ providedIn: 'root' })
export class  PageService {

    constructor(private http: HttpClient) { }
    private _url: string = environment.apiUrl;
    private serviceUrl: string = this._url+"/api/v1/cms/";

    generateOptions(): HttpHeaders {
      let headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
      return headers;
    }

    generateOptionsFD(): HttpHeaders {
      let headers = new HttpHeaders({ 'Content-Disposition': 'multipart/form-data' });
      return headers;
    }

    getPageByCode(code: string): Observable<Page>  {
      return this.http.get<Page>(this.serviceUrl + "page/code?code="+code,  { headers: this.generateOptions() })
      .pipe(map((data: Page) => {
          console.log(data)
          return data;
          }, catchError(err => { 
            return empty();
          }))
      )
    }

    getContentByCode(code: string): Observable<ContentGroup>  {
      return this.http.get<ContentGroup>(this.serviceUrl + "content/code?code="+code,  { headers: this.generateOptions() })
      .pipe(map((data: ContentGroup) => {
          console.log(data)
          return data;
          }, catchError(err => { 
            return empty();
          }))
      )
    }


    getFeaturedNews(code: string): Observable<ContentGroup>  {
      return this.http.get<ContentGroup>(this.serviceUrl + "blog/featured",  { headers: this.generateOptions() })
      .pipe(map((data: ContentGroup) => {
          console.log(data)
          return data;
          }, catchError(err => { 
            return empty();
          }))
      )
    }

    getPaginatedNews(searchString: string = "",
      pageNo: number = 1,
      pageSize: number = 10 ): Observable<Table> {
        return this.http.get<Table>(this.serviceUrl+ "/all?searchString="+ searchString +"&pageNo=" + pageNo+ "&pageSize=" + pageSize,{ headers: this.generateOptions() })
      .pipe(map((data: Table) => {
          console.log(data)
          return data;
          }, catchError(err => { 
            return empty();
          }))
      )
    }

}