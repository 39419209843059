import { Component, OnInit } from '@angular/core';
import { Page, PageComponent } from 'src/app/models/page';
import { PageService } from 'src/app/service/page.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.less']
})
export class VisionComponent implements OnInit {

  pageCode: string = "VM0001"
  pageResource: Page;
  pageComponentMisson: PageComponent;
  pageComponentVision: PageComponent;

  constructor(private _service: PageService, private titleService: Title) {
    this.titleService.setTitle("Vision & Mission | Uniphar Medtech");
  }

  ngOnInit(): void {
    this.loadReferenceData();
  }

  loadReferenceData(){
    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
          this.pageResource = result as Page;
          this.pageComponentMisson = result.pageComponents.find(x => x.id == 18);
          this.pageComponentVision = result.pageComponents.find(x => x.id == 17);
          console.log(this.pageResource);
      }
  });

  }

  gotoMission(elem) {
    var _top = (elem as HTMLDivElement).offsetHeight;
    window.scrollTo({
      top: _top,
      behavior: "smooth"
    });
  }

  gotoVision() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

}
